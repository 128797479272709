<template>
<div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br />
        <div class="container fluid">
          <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="gtseries()">
          <div class="card" style="width:260px;height:180px;">
          
            <img
              style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../../assets/images/subcategorie/soldering_rework/soldering/GT120-HP-T6.jpg"
              alt="Card image cap"
            />
            
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                GT series
              </h4>
            </div>
          </div>
          </a>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="cvseries()">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../../assets/images/subcategorie/soldering_rework/soldering/CV-5210.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                CV Series
              </h4>
            </div>
          </div>
          </a>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="mxseries()">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../../assets/images/subcategorie/soldering_rework/soldering/MX-5210.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                MX Series
              </h4>
            </div>
          </div>
          </a>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="mfrseries()">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../../assets/images/subcategorie/soldering_rework/soldering/MFR-1110.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                MFR series
              </h4>
            </div>
          </div>
          </a>
        </div>
        <!-- end col -->
      </div>
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="psseries()">
          <div class="card" style="width:260px;height:180px;">
          
            <img
              style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../../assets/images/subcategorie/soldering_rework/soldering/PS-900.jpg"
              alt="Card image cap"
            />
            
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                PS Series
              </h4>
            </div>
          </div>
          </a>
        </div>
        <!-- end col -->
        </div>
      </div>
         <Footer />
        </div>
</template>
<script>

import Header from '../../../components/Header.vue';

import Footer from '../../../components/Footer.vue';

/**
 * Dashboard component
 */
export default {
  components: {
   
    Header,
  
    Footer
    
   
  },
  methods : {
     gtseries(){
      window.location.href = "https://store.metcal.com/en-us/shop/soldering-desoldering/soldering-desoldering-systems/gt-series/";

    },
     cvseries(){
       window.location.href = "https://store.metcal.com/en-us/shop/soldering-desoldering/soldering-desoldering-systems/cv-series/CV-5210";

    },
     mxseries(){
       window.location.href = "https://store.metcal.com/en-us/shop/soldering-desoldering/soldering-desoldering-systems/mx-series/MX-5210";

    },
     mfrseries(){
       window.location.href = "https://store.metcal.com/en-us/shop/soldering-desoldering/soldering-desoldering-systems/mfr-series/MFR-1110";

    },
     psseries(){
       window.location.href = "https://store.metcal.com/en-us/shop/soldering-desoldering/soldering-desoldering-systems/ps-series/PS-900";

    },
  }
  
};
</script>